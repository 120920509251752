import { formatUnits } from "ethers";

export const formatVotingPower = (value: string, decimals: number = 18) => {
  try {
    // Remove any commas from the input to ensure consistent formatting
    const sanitizedValue = value.replace(/,/g, "");

    // If the value has decimals, format it as is, without BigInt conversion
    if (sanitizedValue.includes(".")) {
      // Format the integer and fractional parts separately
      const [integerPart, fractionalPart] = sanitizedValue.split(".");
      const formattedIntegerPart = integerPart.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ",",
      );

      // Return only the integer part if `fractionalPart` is empty or zero
      return fractionalPart && parseInt(fractionalPart) > 0
        ? `${formattedIntegerPart}.${fractionalPart}`
        : formattedIntegerPart;
    }

    // For whole numbers, convert using formatUnits for consistent unit handling
    const formatted = formatUnits(sanitizedValue, decimals);
    const integerPart = formatted.split(".")[0];
    return integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } catch (error) {
    console.error("Error formatting voting power:", error);
    return "0";
  }
};
